import React from "react";
import "./App.css";
import lang from "./languages/lang";
import logo from "./logo.png";

const redirectAlert = "https://mpg.football";
const apiPath = "https://api.mpg.football/";

class App extends React.Component {
  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const mpgUserId = params.get("mpgUserId");
    const email = params.get("email");
    const optin = params.get("optin");

    this.state = {
      state: 0,
      loader: true,
      mpgUserId: mpgUserId || null,
      email: email || null,
      optin: optin || null,
      language: "fr",
    };
  }

  langExist = (lang) => {
    return lang === "fr" || lang === "en" || lang === "es";
  };

  getBrowserLanguage = (defaultLanguage) => {
    if (this.langExist(window.navigator.language.substr(0, 2)))
      return window.navigator.language.substr(0, 2);
    else return defaultLanguage;
  };

  getLanguage = (language) => {
    const defaultLanguage = this.state.language;

    if (language !== null && typeof language !== "undefined") {
      let tmpLanguage = language.substr(0, 2);
      if (this.langExist(tmpLanguage)) return tmpLanguage;
      else return this.getBrowserLanguage(defaultLanguage);
    } else {
      return this.getBrowserLanguage(defaultLanguage);
    }
  };

  sendUnsubscribedAllRequest = (mpgUserId, email) => {
    this.setState({
      loader: true,
    });
    fetch(
      `${apiPath}hook/sendinblue/unsubscribe?userId=${mpgUserId}&email=${encodeURIComponent(
        email
      )}&all=true`
    )
      .then((response) => response.json())
      .then((data) => {
        this.requestOnSuccess(2, data);
      })
      .catch(() => {
        this.requestOnError();
      });
  };

  requestOnSuccess = (state, data) => {
    if (data.success) {
      this.setState({
        loader: false,
        state: state,
        language: this.getLanguage(data.language) || "fr",
      });
    } else {
      this.requestOnError();
    }
  };

  requestOnError = () => {
    this.setState({
      state: 1,
      loader: false,
    });
  };

  componentDidMount() {
    if (this.state.mpgUserId && this.state.email && this.state.optin) {
      this.sendUnsubscribedRequest(
        this.state.mpgUserId,
        this.state.email,
        this.state.optin
      );
    } else {
      this.setState({
        state: 1,
        loader: false,
      });
    }
  }

  sendUnsubscribedRequest = (mpgUserId, email, optin) => {
    fetch(
      `${apiPath}hook/sendinblue/unsubscribe?userId=${mpgUserId}&email=${encodeURIComponent(
        email
      )}&optin=${optin}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.requestOnSuccess(0, data);
      })
      .catch(() => {
        this.requestOnError();
      });
  };

  getAlertCategoryName = () => {
    if (this.state.optin !== null)
      return this.translate(`unsubscribre.${this.state.optin}`);
    else return "[error]";
  };

  translate = (id, params = {}) => {
    let translate = lang[this.state.language][id] || "";
    translate = translate.replace(
      "{alert_Category_Name}",
      params.alert_Category_Name || ""
    );
    return translate;
  };

  unsubscribeAll = () => {
    this.sendUnsubscribedAllRequest(this.state.mpgUserId, this.state.email);
    this.setState({
      state: 2,
    });
  };

  titleRender = () => {
    if (this.state.state === 0) {
      return (
        <div className="text">{this.translate("unsubscribre.title.first")}</div>
      );
    }
    if (this.state.state === 2) {
      return (
        <div className="text">{this.translate("unsubscribre.title.third")}</div>
      );
    }
  };

  textCenter = () => {
    if (this.state.state === 0) {
      return (
        <div className="textRetryGlobal">
          <div className="textRetry">
            {this.translate("unsubscribre.center.first", {
              alert_Category_Name: this.getAlertCategoryName(),
            })}
          </div>
        </div>
      );
    }
    if (this.state.state === 1) {
      return (
        <div className="text">
          {this.translate("error", {
            alert_Category_Name: this.getAlertCategoryName(),
          })}
        </div>
      );
    }
    if (this.state.state === 2) {
      return (
        <div className="textRetryGlobal">
          <div className="textRetry">
            {this.translate("unsubscribre.center.third")}
          </div>
          <a
            href={redirectAlert}
            className="textRetry"
            style={{ textDecoration: "underline" }}
          >
            {this.translate("unsubscribre.center.third.redirect")}
          </a>
        </div>
      );
    }
  };

  successChecker = () => {
    if (this.state.state === 0 || this.state.state === 2) {
      return (
        <div className="success-checkmark">
          <div className="check-icon">
            <span className="icon-line line-tip" />
            <span className="icon-line line-long" />
            <div className="icon-circle" />
            <div className="icon-fix" />
          </div>
        </div>
      );
    }
  };

  textBottom = () => {
    if (this.state.state === 0) {
      return (
        <div
          className="textClickableGlobal"
          onClick={() => this.unsubscribeAll()}
        >
          <div style={{ cursor: "pointer" }} className="textClickable">
            {this.translate("unsubscribre.bottom.first")}
          </div>
        </div>
      );
    }
  };

  render() {
    if (!this.state.loader) {
      return (
        <div>
          <div className="header">
            <img src={logo} alt="MPG Logo" className="logo" />
          </div>
          <div>
            <div className="cover">
              {this.titleRender()}
              {this.successChecker()}
              {this.textCenter()}
              {this.textBottom()}
            </div>
          </div>
          <div className="footer" />
        </div>
      );
    } else {
      return (
        <div>
          <div className="header">
            <img src={logo} alt="MPG Logo" className="logo" />
          </div>
          <div>
            <div className="cover">
              <div className="lds-facebook">
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
          <div className="footer" />
        </div>
      );
    }
  }
}

export default App;
